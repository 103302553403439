<template>
  <div class="container">
    <div class="row d-flex" v-if="insertable">
      <div class="col">
        <el-form ref="reportform" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="updating">
          <div class="row pt-0">
            <div class="col-md-4 pr-md-1">
            <label class="form-label">统计月份</label>
            <el-form-item prop="month" :rules="[{required:true}]">
                <el-date-picker v-model="model.month" type="month" class="fw" placeholder="选择月份"></el-date-picker>
            </el-form-item>
            </div>
            <div class="col-md-3 pr-md-1 pl-md-1">
            <label class="form-label">总消费金额</label>
            <el-form-item prop="totalAmount" :rules="[{required:true}]">
                <el-input type="number" v-model="model.totalAmount" placeholder="万元,保留两位小数"></el-input>
            </el-form-item>
            </div>
            <div class="col-md-3 pr-md-1 pl-md-1">
            <label class="form-label">扶贫商品总额</label>
            <el-form-item prop="fupinAmount" :rules="[{required:true}]">
                <el-input type="number" v-model="model.fupinAmount" placeholder="万元,保留两位小数"></el-input>
            </el-form-item>
            </div>
            <div class="col-md-2 pl-md-1">
              <label class="form-label text-white">-</label>
              <el-form-item prop="insert">
                  <el-button type="success" class="inline-button ls-1" @click="submit">插入</el-button>
              </el-form-item>
            </div>
          </div>
          <div class="row pt-0">
            <div class="col-md-4 pr-md-0">
            <label class="form-label">消费数量</label>
            <el-form-item prop="shuLiang" :rules="[{required:true}]">
                <el-input type="number" v-model="model.shuLiang" placeholder=""></el-input>
            </el-form-item>
            </div>
            <div class="col-md-4 pr-md-0" v-if="false">
            <label class="form-label">带贫人口</label>
            <el-form-item prop="daiPinRenKou" :rules="[{required:false}]">
                <el-input type="number" v-model="model.daiPinRenKou" placeholder=""></el-input>
            </el-form-item>
            </div>
            <div class="col-md-4 pr-md-0">
              <label class="form-label">产品类型</label>
              <el-form-item prop="productType" :rules="[{required:true}]">
                  <!--<el-input type="string" v-model="model.productType" placeholder=""></el-input>-->
                  <el-select v-model="model.productType" placeholder="请选择">
                      <el-option label="粮油干货" value="粮油干货"></el-option>
                      <el-option label="瓜果蔬菜" value="瓜果蔬菜"></el-option>
                      <el-option label="禽畜蛋奶" value="禽畜蛋奶"></el-option>
                      <el-option label="零食茶酒" value="零食茶酒"></el-option>
                  </el-select>
              </el-form-item>
            </div>
            <div class="col-md-10 pr-md-0">
                    <label class="form-label">上传消费扶贫购买凭证，如发票、盖章证明（支持jpg,png图片格式 每张不超过2M）</label>
                    <el-form-item prop="poster" :rules="[{required:false}]">
                        <el-upload 
                            class="image-uploader"
                            name="image"
                            action="/backend/api/uploadimage"
                            list-type="picture-card"
                            :show-file-list="true"
                            :on-success="handlePosterSuccess"
                            :on-remove="handleRemove"
                            accept="image/png, image/jpeg">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                       
                    </el-form-item>
                    </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <el-table refs="reportTable" row-key="id" header-row-class-name="thead-light" :height="autoHeight?300:400" :class="tableClass" style="width: 100%" v-loading="loading"
          :data="tableData" stripe @expand-change="expandChange">
          <el-table-column type="expand" width="30">
            <template slot-scope="{row}">
              <div class="row d-flex px-md-3">
                <div class="col-md-9">
                  <h6>修改历史</h6>
                  <report-histories v-if="row.expanded" :key="'history-store-report-'+row.id" 
                    :auto-height="true" table-class="bg-white" :reportid="row.id" type="store">
                  </report-histories>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="month" label="月份" class-name="pl-2" width="80">
            <template slot-scope="{row}">
                {{ row.year }}-{{ formatInt2(row.month,2) }}
            </template>
          </el-table-column>
          <el-table-column prop="total_amount" label="总额(万元)" width="80">
            <template slot-scope="{row}">
                <span v-show="!row.editing">{{ row.total_amount ? parseFloat(row.total_amount).toFixed(2) : '0.00' }}</span>
                <el-input size="mini" type="number" placeholder="0.00"
                  v-if="editable" v-show="row.editing" v-model="row.newVals.total_amount">
                </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fupin_amount" label="扶贫额(万元)" width="100">
            <template slot-scope="{row}">
                <span v-show="!row.editing">{{ row.fupin_amount ? parseFloat(row.fupin_amount).toFixed(2) : '0.00' }}</span>
                <el-input size="mini" type="number" placeholder="0.00"
                  v-if="editable" v-show="row.editing" v-model="row.newVals.fupin_amount">
                </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="shuliang" label="消费数量" width="80">
            <template slot-scope="{row}">
                <span v-show="!row.editing">{{ row.shuliang ? parseFloat(row.shuliang).toFixed(2) : '0' }}</span>
                <el-input size="mini" type="number" placeholder="0.00"
                  v-if="editable" v-show="row.editing" v-model="row.newVals.shuliang">
                </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="daipinrenkou" label="带贫人口" width="120" v-if="false">
            <template slot-scope="{row}">
                <span v-show="!row.editing">{{ row.daipinrenkou ? parseFloat(row.daipinrenkou).toFixed(2) : '0' }}</span>
                <el-input size="mini" type="number" placeholder="0.00"
                  v-if="editable" v-show="row.editing" v-model="row.newVals.daipinrenkou">
                </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="product_type" label="产品类型" width="80">
            <template slot-scope="{row}">
                <span v-show="!row.editing">{{ row.productType ? row.productType : '' }}</span>
                <el-input size="mini" type="string" placeholder=""
                  v-if="editable" v-show="row.editing" v-model="row.newVals.productType">
                </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="files" property="path" label="票据" width="80"  align="center" >
            <template slot-scope="{row}"  >
                <div class="block" v-for="url in getfiles(row.files)" :key="url">
                  <el-image style="width: 30px; height: 30px" v-show="row.files" 
                    :src=url
                    :preview-src-list="getfiles(row.files)">
                  </el-image>
                  <!--<div v-if="row.files?true:false">
                  <div v-for="(item,index) in row.files.split(';')" :key="index" class="table-flex">
                    <el-image style=" 50px; height: 50px; margin-right: 10px;" 
                    :src="proxyHost+item" 
                    :preview-src-list="[proxyHost+item]"></el-image>-->
                  </div>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="填报时间" min-width="90">
              <template slot-scope="scope">
                  {{ formatDateTime(scope.row.created_at) }}
              </template>
          </el-table-column>
          <el-table-column prop="updated_at" label="修改时间" min-width="90" v-if="editable">
              <template slot-scope="scope">
                  {{ formatDateTime(scope.row.updated_at) }}
              </template>
          </el-table-column>
          <el-table-column width="100px" label="#" class-name="px-2 text-center" label-class-name="action-col-header" v-if="editable">
              <template slot-scope="{row}">
                  <a href="javascript:;" @click="edit(row)" v-show="!row.editing" class="border-bottom mx-1"><i class="el-icon-edit-outline"></i> 修改</a>
                  <a href="javascript:;" @click="cancelEdit(row)" v-show="row.editing" :class="row.updating?'disabled':''" class="text-danger">[取消]</a>
                  <a href="javascript:;" @click="update(row)" v-show="row.editing" :class="row.updating?'disabled':''" class="ml-2">[{{ row.updating ? "···" : "保存" }}]</a>
              </template>
          </el-table-column>
          <!-- <template slot="append">
            <p v-infinite-scroll="fetchData" v-if="loading">Loading...</p>
          </template> -->
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
//import moment from "moment";
//import { Form, FormItem } from 'element-ui';

export default {
  components: {
    // [Form.name]: Form,
    // [FormItem.name]: FormItem,
    'report-histories': () => import('./ReportEditHistories')
  },
  props: {
    storeid: {
        type: Number,
        description: "current object id",
        default: null
    },
    insertable: {
        type: Boolean,
        description: "Can insert data",
        default: true
    },
    editable: {
        type: Boolean,
        description: "Can update data",
        default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    tableClass: {
      type: String,
      default: ''
    }
  },
  watch: {
    storeid(v) {
      if(v) this.fetchData();
      else this.tableData = []
    }
  },
  data() {
    return {
      proxyHost: process.env.VUE_APP_BACKEND_TARGET || 'https://guoqi.ycycsw.cn',
      loading: false,
      updating: false,
      model: {
        storeid: this.storeid,
        month: new Date(),
        totalAmount: '',
        fupinAmount: '',
        shuLiang:'',
        daiPinRenKou: 0,
        productType: '',
        poster:'',
        fileList: []
      },
      tableData: [],
      filters: { limit: 100, page: 1, storeid: this.storeid }
    }
  },
  methods: {
    getdefault(){
      return this.proxyHost + '/uploads/images/card.png'
    },
    getfiles(filestr){
      var showfiles = []
      if(filestr){
        var files = filestr.split(';')
        for(let i=0;i<files.length;i++){
          var item = files[i]
          showfiles.push(this.proxyHost +item)
        }
        //console.log(showfiles)
        return showfiles
      }
      
    },
    formatDateTime(datestr) {
      if(!datestr) return "";
      return moment(datestr).format("YYYY-MM-DD HH:mm")
    },
    formatInt2(num, length) {
        return num<10 ? (Array(length).join('0') + num).slice(-length) : num;
    },
    fetchData() {
      this.loading = true;
      this.axios.get("storereports", { params: { ...this.filters } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data.map((x)=>{
              
              return { updating: false, editing: false, expanded: false, ...x, newVals: { ...x } }
            });
            this.filters.page = res.data.data.page;
        }
        else {
            this.$notifyErr('未能加载企业历史数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    handlePosterSuccess(res, file) {
       this.model.poster = res.url;
       this.model.fileList.push(res.url);
    },
    handleRemove(file, fileList) {
        if(file.status==='success'){
          var url = file.response.url
          let index = 0
          let rmindex = -1
          for(;index<this.model.fileList.length;index++){
            var item = this.model.fileList[index]
            if(item === url) {
              rmindex = index
              break;
            }
          }
         
          if(rmindex != -1) {
            this.model.fileList.splice(rmindex,1)
          }
        }
        
    },
    submit() {
      this.$refs['reportform'].validate((valid) => {
          if (valid) {
              this.save();
          } else {
              return false;
          }
      });
    },
    save() {
      this.updating = true;
      this.model.poster = this.model.fileList.join(';')
      this.axios.post('storereports', this.model).then((res) => {
          this.updating = false;
          if (res.data.errcode == 0) {
              this.fetchData();
              this.$refs['reportform'].resetFields();
          }
          else {
              this.$notifyErr('保存数据失败', res.data.errmsg);
          }
      }).catch((err) => {
          if(err.response) {
              if(err.response.data.error) this.$notifyErr('保存数据失败', err.response.data.error.message);
              else this.$notifyErr('保存数据失败', err.response.data[0].message);
          }
          else this.$notifyErr('保存数据失败', '错误信息: ' + err.message);
          this.updating = false;
      })
    },
    edit(row) {
      row.editing = true
    },
    cancelEdit(row) {
      row.editing = false
    },
    update(row) {
      row.updating = true;
      this.axios.put('storereports/'+row.id, row.newVals).then((res) => {
          row.updating = false;
          if (res.data.errcode == 0) {
              Object.assign(row, res.data.data)
              Object.assign(row.newVals, res.data.data)
              row.editing = false;
          }
          else {
              this.$notifyErr('保存数据失败', res.data.errmsg);
          }
      }).catch((err) => {
          this.$notifyErr('保存数据失败', '错误信息: ' + err.message);
          this.updating = false;
      })
    },
    expandChange(row) {
      row.expanded = !row.expanded
    }
  },
  mounted() {
    this.fetchData()
  }
};
</script>
<style>
.card .table td, .card .table th, .card .el-table td, .card .el-table th {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.fw.el-date-editor.el-input {
  width:100%; 
}
.el-button.inline-button {
  width:100%;
}
.image-uploader .el-upload {
        border: 1px dashed #8c939d;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .image-uploader .el-upload:hover {
        border-color: #2dce89;
    }
    .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 130px;
        height: 130px;
        line-height: 130px;
        text-align: center;
    }
    .image-uploader-icon.poster {
        height: 184px;
        line-height: 184px;
    }
    .avatar-uploader .el-upload:hover .avatar-uploader-icon {
        color: #2dce89;
    }
    .image-upload-preview {
        width: 130px;
        display: block;
    }
</style>